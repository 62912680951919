.responsive-group {
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;

    &:first-child> :first-child {
        margin: 0px;
    }
}

@media (min-width: 1200px) {
    .responsive-group {
        flex-direction: row;
        gap: var(--spacing-md);

        &:nth-child(n + 2) {
            margin-top: var(--spacing-md);
        }
    }
}