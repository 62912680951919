/* Root Variables */
:root {
    /* Color Palette */
    --color-background: #121212;
    --color-surface: #121212;
    --color-error: #ff9182;
    --color-primary: #fdc9b9;
    --color-secondary: #5d624c;
    --color-gold: #fbd66f;

    /* Text Colors */
    --color-text-on-background: rgba(255, 255, 255, 0.87);
    --color-text-on-surface: rgba(255, 255, 255, 0.65);
    --color-text-on-primary: #000000;
    --color-text-on-secondary: #000000;

    /* Spacing */
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 1.5rem;
    --spacing-xl: 2rem;
}

/* Text Sizing Utility Classes */
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.text-5xl {
    font-size: 3rem;
    line-height: 1;
}

.text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}

.text-7xl {
    font-size: 4.5rem;
    line-height: 1;
}

.text-8xl {
    font-size: 6rem;
    line-height: 1;
}

.text-9xl {
    font-size: 8rem;
    line-height: 1;
}

/* Text Weight Utility Classes */
.text-thin {
    font-weight: 100;
}

.text-extralight {
    font-weight: 200;
}

.text-light {
    font-weight: 300;
}

.text-normal {
    font-weight: 400;
}

.text-medium {
    font-weight: 500;
}

.text-semibold {
    font-weight: 600;
}

.text-bold {
    font-weight: 700;
}

.text-extrabold {
    font-weight: 800;
}

.text-black {
    font-weight: 900;
}

/* Global Styles */
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

html,
body {
    background-color: var(--color-background);
    color: var(--color-text-on-background);
    font-family: "Inter", sans-serif;
    line-height: 1.5rem;
}

body {
    font-size: 1rem;
}

/* Reusable Component Styles */
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

button {
    border: none;
    cursor: pointer;
}

a {
    color: var(--color-primary);
    text-decoration: none;
}

ul {
    padding-left: var(--spacing-xl);
}

ul li {
    padding-left: var(--spacing-sm);
}

ul li::marker {
    color: var(--color-text-on-surface);
}

ul li:not(:last-of-type) {
    margin-bottom: 0.125rem;
}

/* Text Styles */
h1,
h2,
h3,
h4,
h5,
h6,
p {
    overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font: inherit;
}


/* Layout and Structure */
#root,
#__next {
    isolation: isolate;
}