.responsive-group {
    align-items: flex-start;
    flex-direction: column;
}

.responsive-icon {
    display: none;
}

.title {
    color: var(--color-primary);
    font-size: 17px;
    font-weight: 500;
}

.date-range {
    color: var(--color-text-on-surface);
    font-size: 14px;
}

@media (min-width: 1200px) {
    .responsive-group {
        align-items: center;
        flex-direction: row;
    }

    .responsive-icon {
        display: block;
    }
}