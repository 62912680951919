.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    gap: var(--spacing-md);
    justify-content: center;
    margin: 0px auto;
    text-align: center;
    width: 100%;
}

.image {
    border-radius: 100%;
    height: 200px;
    width: 200px;
}

.link-group {
    justify-content: center;
}

.link-button {
    background-color: rgba(93, 98, 76, 0.9);
    border-radius: 100%;
    color: var(--color-text-on-background);
    display: block;
    font-size: 0px;
    padding: var(--spacing-sm);
}

.sparkles {
    color: var(--color-gold);
    display: inline-block;
}

@media (min-width: 1200px) {
    .wrapper {
        flex-direction: row;
        justify-content: space-evenly;
        text-align: left;
        width: 1200px;

        & .left {
            max-width: 600px;
        }
    }

    .link-group {
        justify-content: flex-start;
    }
}