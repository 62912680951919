.skill {
    margin-top: var(--spacing-md);
    width: 100%;
}

@media (min-width: 1200px) {
    .skill {
        margin-top: 0px;
        width: 50%;
    }
}