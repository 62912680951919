.wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;

    & .left,
    & .right {
        width: 100%;
    }

    & .left h2 {
        margin-bottom: var(--spacing-md);
    }
}

@media (min-width: 1200px) {
    .wrapper {
        flex-direction: row;
        width: 1200px;

        & .left {
            margin-bottom: none;
            width: calc(1200px * (4 / 12));
        }

        & .right {
            width: calc(1200px * (8 / 12));
        }
    }
}